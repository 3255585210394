export class NotFoundError extends Error {
  constructor() {
    super('Item not found');
    this.name = 'NotFoundError';
  }
}

export class BadRequestError extends Error {
  constructor() {
    super('Your request is invalid');
    this.name = 'BadRequestError';
  }
}
