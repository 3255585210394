import {
  LocalStorageAdapter,
  MemoryStorageAdapter,
  Store,
  CookieStorageAdapter,
} from 'infrastructure/cache';

export const makeLocalStorageAdapter = (): LocalStorageAdapter => new LocalStorageAdapter();
export const makeMemoryStorageAdapter = (): MemoryStorageAdapter =>
  new MemoryStorageAdapter(new Store());
export const makeCookieStorageAdapter = (): CookieStorageAdapter => new CookieStorageAdapter();
