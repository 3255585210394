import { IResourceRepository } from 'domain/repositories/resource';
import { IDomainResponse } from '.';

export class ResourcesInteractor {
  constructor(private readonly repo: IResourceRepository) { }

  async createResource(params: any): Promise<IDomainResponse> {
    try {
      const data = await this.repo.createResource(params);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }

  async getResource(id: number | string): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getResource(id);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }

  async getResources(page: number, filters: any): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getResources(page, filters);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }

  async updateResource(id: number, values: any): Promise<IDomainResponse> {
    try {
      const data = await this.repo.updateResource(id, values);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }

  async deleteResource(id: any): Promise<IDomainResponse> {
    try {
      const data = await this.repo.deleteResource(id);
      return { data, error: null };
    }
    catch (error) {
      return { data: null, error };
    }
  }
}
