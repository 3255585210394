import { createCipheriv, randomBytes, createDecipheriv } from 'crypto';
import { IEncryption } from 'data/protocols/encryption';

type Hash = {
  iv: string;
  content: string;
};

export class CryptoAdapter implements IEncryption {
  algorithm = 'aes-256-ctr';
  secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
  iv = randomBytes(16);

  encrypt(data: any): Hash {
    const cipher = createCipheriv(this.algorithm, this.secretKey, this.iv);

    const encrypted = Buffer.concat([cipher.update(data), cipher.final()]);

    return {
      iv: this.iv.toString('hex'),
      content: encrypted.toString('hex'),
    };
  }

  decrypt(hash: Hash): any {
    const decipher = createDecipheriv(this.algorithm, this.secretKey, Buffer.from(hash.iv, 'hex'));

    const decrpyted = Buffer.concat([
      decipher.update(Buffer.from(hash.content, 'hex')),
      decipher.final(),
    ]);

    return decrpyted.toString();
  }
}
