import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/react';
import React from 'react';

export function Loading(props: any) {
  return (
    <Center>
      <Spinner {...props} />
    </Center>
  );
}
